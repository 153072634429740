import "./style/Coaches.css";
import logo from "../../assets/images.png";

// import {FiEdit} from "react-icons/fi";
// import {AiOutlineDelete} from "react-icons/ai";
import { useEffect, useState } from "react";
import { getAllTransactions, getTotalTrans ,getAllShopify} from "../apicalls/transaction";
import { isAuthenticated } from "../apicalls/auth";
import Paginations from "./Paginations";
import debounce from "lodash.debounce";
import moment from 'moment';
function Shopify() {
  const [temp, setTemp] = useState([]);

  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [coach, setCoachsIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage, setPostPerPage] = useState(10);

  const user = isAuthenticated();

  useEffect(() => {
    getAllShopify(user.access_token).then((data) => {
      setFilteredDataSource(data.data);
      setTransactions(data.data)
    });
 
  }, []);
  useEffect(() => {
    let ids = transactions.map( (item) => item.coachId);
    setCoachsIds(ids);
  }, [transactions?.length>0]);

  const handleChange = (e) => {
    setPostPerPage(e.target.value);
  };
  const handleCoachIdSearch = (e) => {
let id=e.target.value;
let filter;
if(id=='All'){
setFilteredDataSource(transactions);
}else{ filter = 
  transactions.find(e => e.coachId === id);
  setFilteredDataSource([filter])}
    // setPostPerPage(e.target.value);
  };

  const handleSearch = (e) => {
    // console.log("e.target.value",e.target.value);
    setSearch(e.target.value);
  };

  const deBounceOnChange = debounce(handleSearch, 500);

  const paginate = (number) => {
    setCurrentPage(number);
  };

  function percentage(num, per)
{
  return (num/100)*per;
}

  return (
    <main>
      <div className="coaches_title">
        <h1>Shopify orders</h1>
      </div>
      <div className="filter">
        
          <select className="w-25" name="" id="" onChange={handleCoachIdSearch}>
            <option key='All' value={'All'}>All</option>
              {coach.map((val) => {
                return (
                  <option key={val} value={val}>
                    coach {val}
                  </option>
                );
              })}
            </select>
        {/* <input
          className="w-25"
          type="text"
          onChange={deBounceOnChange}
          placeholder="Search"
        /> */}

      </div>

      <div className="col-11">
     
        <div className="table-responsive">
        <table className="table table-condensed table-striped">
    <thead>
        <tr>
					<th></th>
          <th>Coach Name</th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
    </thead>

    <tbody>
    {filteredDataSource?.length > 0 ? (
                filteredDataSource.map((trans,idx) => {
                  return (<>
                    <tr key={trans.coachId} data-bs-toggle="collapse" data-bs-target={"#demo"+idx} className="accordion-toggle">
           <td><button className="btn btn-default btn-xs"><span className="fa fa-eye"></span></button></td>
            <td colspan='5'>Coach {trans.coachId} </td>
            
        </tr>
         <tr>
            <td colspan="12" className="hiddenRow">
							<div className="accordian-body collapse" id={'demo'+idx}> 
              <table className="table table-striped table-hover">
                      <thead>
                        <tr className="info">
													<th >Month wise</th>
													<th>Total</th>
													<th>Commision</th>		
													<th></th>	
													<th></th>	
													<th>Expand</th>	
												</tr>
											</thead>	
              
											<tbody>
                      {Object.keys(trans).map((key, index) => {
                          // return <p>fddfdf</p>
                          if(key!=='coachId'){
                            let tots=trans[key].reduce(function (acc, obj) { return acc + Number(obj.total_price); }, 0).toFixed(2)
                            return (<><tr data-bs-toggle="collapse"  className="accordion-toggle" data-bs-target={"#demo10"+index}>
                            <td> <a href="#">{key}</a></td>
                            <td>${tots}</td>
                            <td>${percentage(tots,10).toFixed(2)} </td>
                            <td> </td>
                            <td> </td>
                            <td> 
                              <a href="#" className="btn btn-default btn-sm">
                                    <i className="fa fa-bars"></i>
                                </a>
                            </td>
                          </tr>
                          
                          
                          <tr>
                          <td colspan="12" className="hiddenRow">
                            <div className="accordian-body collapse" id={"demo10"+index}> 
                            <table className="table  table-hover">
                                    <thead class="thead-dark">
                                     
                                      <tr>
                                        <th scope="col" className="text-center">#Order id</th>
                                        <th scope="col" className="text-center">Created At</th>
                                        <th scope="col" className="text-center">Total Price</th>
                                        <th scope="col" className="text-center">UserEmail </th>
                                        <th scope="col" className="text-center">Currency</th>
                                        <th scope="col" className="text-center">Financial Status</th>
                                        <th scope="col" className="text-center">Fulfillment Status</th>
                                        <th scope="col" className="text-center">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>{
                                      trans[key].map((key, index) => {
                                        
                                        return  (<tr>
                                          <th scope="row" className="text-center">#{key.id}</th>
                                        <td className="text-center">{moment(key.created_at).format('YYYY MMM DD')}</td>
                                        <td className="text-center">{key.total_price}</td>
                                        <td className="text-center">{key.user_email}</td>
                                        <td className="text-center">{key.currency}</td>
                                        <td className="text-center">{key.financial_status}</td>
                                        <td className="text-center">{key.fulfillment_status?key.fulfillment_status:"nil"}</td>
                                        <td className="text-center">
                                            <a href={`${key.order_status_url}`} className="btn btn-default btn-sm" target="_blank" rel="noopener noreferrer">
                                              <i className="fa fa-eye"></i>
                                            </a>
                                        </td>
                                      </tr>)
                                      })}
                                     
                                    </tbody>
                               </table>
                            
                            </div> 
                        </td>
                      </tr></>)
    }
})}
											
								
               
                      </tbody>
               	</table>
              
              </div> 
          </td>
        </tr>
        </>
                  );
                })
              ) : (
                <tr>
                    <td colspan='6'>Not records </td>
                </tr>
              )}
      
    </tbody>
</table>
        </div>
        {search ? (
          " "
        ) : (
          <Paginations
            perPage={PerPage}
            total={total?.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        )}
      </div>
    </main>
  );
}

export default Shopify;
