import { API } from "../../constAPI";

export const getAllShopify = (token, perPage, page, search) => {
  // limit = limit ? limit : undefined;
  // search = search ? search : undefined;
  return fetch(`${API}/api/admin/shopify/orders`,
    {  method: 'GET',
    mode: 'cors',
      headers: {
        Authorization: `token ${token}`,

        // "Content-Type": "application/graphql",
        // "X-Shopify-Access-Token": "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",
      },
    }
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
};
export const getAllTransactions = (token, perPage, page, search) => {
  // limit = limit ? limit : undefined;
  // search = search ? search : undefined;
  return fetch(
    `${API}/api/admin/transactions?${new URLSearchParams({
      search,
      perPage,
      page,
    })}`,
    {
      headers: {
        Authorization: `token ${token}`,
        Accept: "application/json",
        "Content-type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const getTotalTrans = (token) => {
  // limit = limit ? limit : undefined;
  // search = search ? search : undefined;
  return fetch(`${API}/api/admin/trans/total`, {
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const getAllTransactionsTotal = (token) => {
  // limit = limit ? limit : undefined;
  // search = search ? search : undefined;
  return fetch(`${API}/api/admin/account/total`, {
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
};
