import "./style/PopupForm.css";
import {GrClose} from "react-icons/gr";
import {useEffect, useState} from "react";
import {addPlayer, getPlayer, updatePlayer} from "../../apicalls/players";
import {signupCoach} from "../../apicalls/coaches";
import {isAuthenticated} from "../../apicalls/auth";

function PlayerForm({action, setPopup, player_id}) {
  const [values, setValues] = useState({
    full_name: "",
    phone: 0,
    password: "",
    email: "",
    date_of_birth: "",
    error: false,
    success: false,
    loading: false,
  });

  const user = isAuthenticated();

  let {
    full_name,

    phone,
    password,
    date_of_birth,
    email,
    error,
    success,
    loading,
  } = values;

  useEffect(() => {
    if (action === "Edit") {
      getPlayer(player_id, user.access_token).then((data) => {
        setValues({
          ...values,
          full_name: data[0]?.full_name,
          phone: data[0]?.phone,
          date_of_birth: new Date(data[0]?.date_of_birth)
            .toISOString()
            .slice(0, 10),
        });
      });
    }
  }, []);

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      error: false,
      success: false,
      [name]: event.target.value,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setValues({...values, error: false, success: false, loading: false});
    if (action === "Add") {
      if (!full_name || !email || !password) {
        setValues({
          ...values,
          error: "please fill required fields",
          success: false,
        });
        return;
      }
      signupCoach({email, password, user_type: 2}).then((data1) => {
        if (data1?.err?.code === "23505") {
          setValues({
            ...values,
            error: "Email already Exist",
            success: false,
            loading: false,
          });
          return;
        }
        date_of_birth = date_of_birth ? date_of_birth : null;
        addPlayer(
          {
            full_name,
            phone,
            user_id: data1.user_id,
            date_of_birth,
          },
          user.access_token
        ).then((data) => {
          if (data[0]?.id) {
            setValues({
              ...values,
              full_name: "",
              email: "",
              password: "",
              phone: "",
              date_of_birth: "",
              error: false,
              success: true,
              loading: false,
            });
          } else {
            setValues({
              ...values,
              error: "Please enter required values",
              success: false,
              loading: false,
            });
          }
        });
      });
    }
    if (action === "Edit") {
      if (!full_name || !phone || !date_of_birth) {
        setValues({...values, error: "please fill fields", success: false});
        return;
      }
      updatePlayer(
        player_id,
        {
          full_name,
          phone,
          date_of_birth,
        },
        user.access_token
      ).then((data) => {
        if (data[0].id) {
          setValues({
            ...values,
            full_name: "",
            phone: "",
            date_of_birth: "",
            error: false,
            success: true,
            loading: false,
          });
        } else {
          setValues({
            ...values,
            error: "Please fill required fields",
            success: false,
            loading: false,
          });
        }
      });
    }
  };

  return (
    <div className="popup">
      <div className="popup-inner">
        <div
          className="close-btn"
          onClick={() => {
            setPopup("");
          }}
        >
          <GrClose />
        </div>
        <h1>{action} Player</h1>.
        <div className="container">
          <div className="row">
            {error ? <span className="error">{error}</span> : ""}
            {success ? (
              <span className="success">
                Player {action === "Add" ? "Added" : "Edited"} Successfully
              </span>
            ) : (
              ""
            )}
            <div className="form-content col-lg-6">
              <label htmlFor="">Full Name*</label>
              <br />
              <input
                type="text"
                value={full_name}
                onChange={handleChange("full_name")}
                placeholder="Enter Full Name"
                required
              />
            </div>
            {action === "Add" ? (
              <div className="form-content col-lg-6">
                <label htmlFor="">Email Address*</label>
                <br />
                <input
                  type="email"
                  value={email}
                  onChange={handleChange("email")}
                  placeholder="Enter Email Address"
                  required
                />
              </div>
            ) : (
              ""
            )}
            <div className="form-content col-lg-6">
              <label htmlFor="">phone number</label>
              <br />
              <input
                type="number"
                value={phone}
                onChange={handleChange("phone")}
                placeholder="Enter phone number"
              />
            </div>
            <div className="form-content col-lg-6">
              <label htmlFor="">Date of birth</label>
              <br />
              <input
                type="date"
                value={date_of_birth}
                onChange={handleChange("date_of_birth")}
              />
            </div>
            {action === "Add" ? (
              <div className="form-content col-lg-6">
                <label htmlFor="">Create Password*</label>
                <br />
                <input
                  type="password"
                  value={password}
                  onChange={handleChange("password")}
                  placeholder="player password"
                />
              </div>
            ) : (
              ""
            )}
            <div className="form-content col-lg-12">
              <button onClick={onSubmit}>{action} Player</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayerForm;
