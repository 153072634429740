import React, { useEffect,useState } from 'react';

import { GrClose } from "react-icons/gr";
import {
  addPlayerToCoachRoster,
  getCoach,
  signupCoach,
  updateCoach,
  getCoachNotPlayers,
} from "../../apicalls/coaches";

import SelectWrapper from './SelectWrapper';

import { isAuthenticated } from "../../apicalls/auth";
const CoachPlayerForm = ({ action, setPopup, coach_id, playersID})  => {
  console.log({playersID});
  const user = isAuthenticated();
  //Checks if there are more pages to be loaded
  const [selectedValue, setSelectedOption] = useState('');

   
    const [selectedPlayer, setSelectedPlayer] = useState('');
    const [formMessage, setFormMessage] = useState(null); // State to hold form submission response


    const handleSelection = (selectedItem) => {
      setSelectedPlayer(selectedItem); // Update the selected player state
      console.log('Selected Player:', selectedItem);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Submitted:', selectedPlayer);
        // Add your logic here to submit data to the server or perform other actions
      
        addPlayerToCoachRoster(
          {
            coach_id:Number(coach_id),
            player_id:selectedPlayer.user_id,
            status:'PENDING'
          },
          user.access_token,
          coach_id
        ).then((data) => {
          // console.log("addPlayerToCoachRoster",data);
          // console.log("addPlayerToCoachRoster2",data?.code);
          if (data?.code === '23505') {
            setFormMessage('Error: Player already exists in the roster.');
          } else if (data?.id) {
            setFormMessage('Player successfully added to the roster.');
          } else {
            setFormMessage('An unexpected error occurred.');
          }
        });
    };
    const handleSelectItem = (selected) => {
      setSelectedPlayer(selected); // Update the selected player state
      console.log('Selected Player:', selected);
    };
    console.log({formMessage});
    return (<div className="popup ">
    <div className="popup-inner">
      <div
        className="close-btn"
        onClick={() => {
          setPopup("");
        }}
      >
        <GrClose />
      </div>
      
      <h1>{action} Player to Coach rooster</h1>.
        <form onSubmit={handleSubmit} className="container mt-4">
            <div className="mb-3" style={{ position: 'relative' }}>
            {selectedPlayer?<p>Selected: {selectedPlayer.email}</p>:null}
                <SelectWrapper
          value={selectedValue}
          placeholder="Select"
          isClearable
          coach_id={coach_id}
          user={user}
             playersID={playersID}
          // loadNextPage={loadNextPage}
          onSelectItem={handleSelectItem}
            onChange={(selected) => {
              setSelectedOption(selected);
              handleSelection(selected); // Pass the selected item to the parent component
            }}
          
        />
                
            </div>
            <div className="mb-3">
            {formMessage && (
              <p className={`alert ${formMessage.includes('successfully') ? 'alert-success' : 'alert-danger'}`}>
                {formMessage}
              </p>
            )}
          </div>

            <button type="submit" className="btn btn-primary">Submit</button>
        </form></div>
    </div>
    );
};

export default CoachPlayerForm;
