import { API } from "../../constAPI";

export const getAllPlayers = (page, perPage, search, token) => {
  // limit = limit ? limit : undefined;
  // search = search ? search : undefined;
  return fetch(
    `${API}/api/admin/players?${new URLSearchParams({
      search,
      perPage,
      page,
    })}`,
    {
      headers: {
        Authorization: `token ${token}`,
        Accept: "application/json",
        "Content-type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
};
export const getCoachesAllPlayers = (page, perPage, search, token,id) => {
  // limit = limit ? limit : undefined;
  // search = search ? search : undefined;
  return fetch(
    `${API}/api/admin/coachesplayers?${new URLSearchParams({
      search,
      perPage,
      page,
      id,
    })}`,
    {
      headers: {
        Authorization: `token ${token}`,
        Accept: "application/json",
        "Content-type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const addPlayer = (post, token) => {
  return fetch(`${API}/api/admin/player`, {
    method: "POST",
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(post),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const getPlayer = (id, token) => {
  return fetch(`${API}/api/admin/player/${id}`, {
    method: "GET",
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const getTotalPlayers = (token) => {
  // limit = limit ? limit : undefined;
  // search = search ? search : undefined;
  return fetch(`${API}/api/admin/player/number/total`, {
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const updatePlayer = (id, post, token) => {
  return fetch(`${API}/api/admin/player/${id}`, {
    method: "PATCH",
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(post),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const deletePlayer = (id, token) => {
  return fetch(`${API}/api/admin/player/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};
