import "./Menu.css";

const Menu = ({sidebarOpen, openSidebar}) => {
  return (
    <nav className="app_navbar">
      <div className="nav_icon" onClick={() => openSidebar()}>
        <i className="fa fa-bars"></i>
      </div>
    </nav>
  );
};

export default Menu;
