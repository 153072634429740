import "./style/Coaches.css";

// import logo from "../../assets/eleven.jpg";
import logo from "../../assets/images.png";

import PlayerForm from "./PopupForm/PlayerForm";
import { useEffect, useState } from "react";
import DeletePopup from "./PopupForm/DeletePopup";

// import {GrView} from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { getAllPlayers, getTotalPlayers } from "../apicalls/players";
import { isAuthenticated } from "../apicalls/auth";
import Paginations from "./Paginations";
import debounce from "lodash.debounce";

function Players() {
  const [temp, setTemp] = useState([]);

  const [addpopup, setaddPopup] = useState(false);
  const [editpopup, seteditPopup] = useState(false);
  const [deletepopup, setdeletePopup] = useState(false);
  const [players, setPlayers] = useState([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage, setPerPage] = useState(10);

  const user = isAuthenticated();

  const handleChange = (e) => {
    setPerPage(e.target.value);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const deBounceOnChange = debounce(handleSearch, 500);

  useEffect(() => {
    getAllPlayers(currentPage, PerPage, search, user.access_token).then(
      (data) => {
        setPlayers(data);
      }
    );

    getTotalPlayers(user.access_token).then((data) => {
      setTotal(data);
      let temp2 = [];
      for (let i = currentPage; i <= Math.ceil(data.length / 10); i++) {
        temp2.push(i * 10);
      }
      setTemp(temp2);
    });
  }, [addpopup, editpopup, deletepopup, PerPage, search, currentPage]);

  // const indexOfLast = currentPage * PerPage;
  // const indexOfFirst = indexOfLast - PerPage;
  // const current =
  //   players.length > 0 ? players?.slice(indexOfFirst, indexOfLast) : players;

  const paginate = (number) => {
    setCurrentPage(number);
  };

  return (
    <main>
      <div className="coaches_title">
        <h1>Players</h1>
        <button
          className="coaches_button"
          onClick={() => {
            setaddPopup(true);
          }}
        >
          Add Player +
        </button>
      </div>
      {addpopup && <PlayerForm action="Add" setPopup={setaddPopup} />}
      <div className="filter">
        {currentPage > 1 ? (
          ""
        ) : (
          <div>
            <select name="" id="" onChange={handleChange}>
              {temp.map((val) => {
                return (
                  <option key={val} value={val}>
                    {val}
                  </option>
                );
              })}
            </select>
            <span>
              {" "}
              show {PerPage} out of {total?.length}
            </span>
          </div>
        )}
        <input
          className="w-25"
          type="text"
          onChange={deBounceOnChange}
          placeholder="Search"
        />
      </div>

      <div className="col-11">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">phone no.</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {players.length > 0 ? (
                players.map((player) => {
                  return (
                    <tr key={player.id}>
                      <td className="fw-bold">
                        <img
                          src={player.profile_picture}
                          alt=""
                          id="table_img"
                        />{" "}
                        {player.full_name}
                        <span className="text-danger fw-normal">
                          {player.is_deleted ? " ( deleted player )" : ""}
                        </span>
                      </td>
                      <td>{player.email}</td>
                      <td>{player.phone}</td>

                      <td>
                        <FiEdit
                          className="icon-cursor"
                          onClick={() => {
                            seteditPopup(player.id);
                          }}
                        />
                        {editpopup === player.id && (
                          <PlayerForm
                            player_id={player.id}
                            action="Edit"
                            // person="Player"
                            setPopup={seteditPopup}
                          />
                        )}
                      </td>
                      {!player.is_deleted ? (
                        <td>
                          <AiOutlineDelete
                            className="icon-cursor"
                            onClick={() => {
                              setdeletePopup(player.id);
                            }}
                          />
                          {deletepopup === player.id && (
                            <DeletePopup
                              player_id={player.id}
                              setPopup={setdeletePopup}
                            />
                          )}
                        </td>
                      ) : (
                        <span className="text-danger">deleted</span>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>
                    <h2>Players not Available</h2>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {search ? (
          " "
        ) : (
          <Paginations
            perPage={PerPage}
            total={total?.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        )}
      </div>
    </main>
  );
}

export default Players;
