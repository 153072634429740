import "./style/DeletePopup.css";
import { GrClose } from "react-icons/gr";
import { isAuthenticated } from "../../apicalls/auth";
import { deleteCoach } from "../../apicalls/coaches";
import { deletePlayer } from "../../apicalls/players";
import { deleteLocation } from "../../apicalls/locations";
import { useEffect, useState } from "react";

function DeletePopup({ setPopup, coach_id, player_id, location_id }) {
  const user = isAuthenticated();
  const [handle, setHandle] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (coach_id) {
      setHandle("Coach");
    } else if (player_id) {
      setHandle("Player");
    } else {
      setHandle("Location");
    }
  }, []);

  const OnDelete = () => {
    if (coach_id) {
      deleteCoach(coach_id, user.access_token).then((data) => {
        setPopup("");
      });
    }

    if (player_id) {
      deletePlayer(player_id, user.access_token).then((data) => {
        setPopup("");
      });
    }

    if (location_id) {
      deleteLocation(location_id, user.access_token).then((data) => {
        if (!data.location) {
          setError(data.detail);
        } else {
          setError(false);
          setPopup("");
        }
      });
    }
  };

  return (
    <div className="popup">
      <div className="popup-inner">
        <div
          className="close-btn"
          onClick={() => {
            setPopup("");
          }}
        >
          <GrClose />
        </div>
        <h1>Delete {handle}</h1>.
        <div className="container">
          {error && <span className="error">{error}</span>}
          <div className="row">
            <span>Are you sure you want to delete {handle}?</span>

            <div className="form-content col-lg-12">
              <button
                onClick={() => {
                  setPopup("");
                }}
              >
                Cancel
              </button>
            </div>
            <div className="form-content col-lg-12">
              <button onClick={OnDelete}>Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeletePopup;
