import { API } from "../../constAPI";

export const getAllLocations = (token, perPage, page, search) => {
  // limit = limit ? limit : undefined;
  // search = search ? search : undefined;
  return fetch(
    `${API}/api/admin/locations?${new URLSearchParams({
      perPage,
      page,
      search,
    })}`,
    {
      headers: {
        Authorization: `token ${token}`,
        Accept: "application/json",
        "Content-type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
};
export const postLocation = (raw, token) => {
 
  return fetch(`${API}/api/admin/getchecklocation`, {
    method: "POST",
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(raw),
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const getTotalLocations = (token) => {
  // limit = limit ? limit : undefined;
  // search = search ? search : undefined;
  return fetch(`${API}/api/admin/location/number/total`, {
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const addLocation = (post, token) => {
  return fetch(`${API}/api/admin/location`, {
    method: "POST",
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(post),
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const getLocation = (id, token) => {
  return fetch(`${API}/api/admin/location/${id}`, {
    method: "GET",
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const updateLocation = (id, post, token) => {
  return fetch(`${API}/api/admin/location/${id}`, {
    method: "PATCH",
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(post),
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const deleteLocation = (id, token) => {
  return fetch(`${API}/api/admin/location/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
};
