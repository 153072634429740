import {useEffect, useState} from "react";
import {isAuthenticated} from "../apicalls/auth";
import {getAllCoaches, getTotal, getTotalCoaches} from "../apicalls/coaches";
import {getAllLocations, getTotalLocations} from "../apicalls/locations";
import {getAllPlayers, getTotalPlayers} from "../apicalls/players";
import {getAllTransactionsTotal, getTotalTrans} from "../apicalls/transaction";
import "./style/Dashboard.css";

function Dashboard() {
  const user = isAuthenticated();

  const [coaches, setCoaches] = useState([]);
  const [players, setPlayers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getTotalCoaches(user.access_token).then((data) => setCoaches(data));
    getTotalPlayers(user.access_token).then((data) => setPlayers(data));
    getTotalLocations(user.access_token).then((data) => setLocations(data));
    getAllTransactionsTotal(user.access_token).then((data) =>
      setTotal(data?.Balance)
    );
  }, []);

  return (
    <main>
      <h1>Dashboard</h1>
      <div className="row">
        <div className="col-lg-2 col-md-6 col-sm-12 shadow p-3 mb-5 bg-white rounded">
          <h2 className="text-center">{coaches?.length}</h2>

          <h2 className="text-center">Coaches</h2>
        </div>
        <div className="col-lg-2 col-md-6 col-sm-12  shadow p-3 mb-5 bg-white rounded">
          <h2 className="text-center">{players?.length}</h2>

          <h2 className="text-center">Players</h2>
        </div>
        <div className="col-lg-2 col-md-6 col-sm-12  shadow p-3 mb-5 bg-white rounded">
          <h2 className="text-center">{locations?.length}</h2>

          <h2 className="text-center">Locations</h2>
        </div>
        <div className="col-lg-2 col-md-6 col-sm-12  shadow p-3 mb-5 bg-white rounded">
          <h2 className="text-center">$ {total}</h2>

          <h2 className="text-center">Total Balance</h2>
        </div>
      </div>
    </main>
  );
}

export default Dashboard;
