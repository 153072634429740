import "./style/Coaches.css";
// import logo from "../../assets/eleven.jpg";
import logo from "../../assets/images.png";
import PopupForm from "./PopupForm/PopupForm";
import DeletePopup from "./PopupForm/DeletePopup";
import { useEffect, useState } from "react";
import { GrView } from "react-icons/gr";

import { FaUserFriends } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import Paginations from "./Paginations";
import debounce from "lodash.debounce";

import { isAuthenticated } from "../apicalls/auth";
import { getAllCoaches, getTotalCoaches } from "../apicalls/coaches";

function Coaches() {
  const [temp, setTemp] = useState([]);
  const [addpopup, setaddPopup] = useState(false);
  const [editpopup, seteditPopup] = useState(false);
  const [deletepopup, setdeletePopup] = useState(false);
  const [coaches, setCoaches] = useState([]);
  const [total, setTotal] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [coachPerPage, setCoachPerPage] = useState(10);

  const user = isAuthenticated();

  useEffect(() => {
    getAllCoaches(coachPerPage, currentPage, search, user.access_token).then(
      (data) => {
        setCoaches(data);
      }
    );

    getTotalCoaches(user.access_token).then((data) => {
      setTotal(data);
      let temp2 = [];
      for (let i = currentPage; i <= Math.ceil(data?.length / 10); i++) {
        temp2.push(i * 10);
      }
      setTemp(temp2);
    });
  }, [addpopup, editpopup, deletepopup, search, coachPerPage, currentPage]);

  // const indexOfLastCoach = currentPage * coachPerPage;
  // const indexOfFirstCoach = indexOfLastCoach - coachPerPage;
  // const currentCoaches =
  //   coaches?.length > 0
  //     ? coaches?.slice(indexOfFirstCoach, indexOfLastCoach)
  //     : coaches;

  const paginate = (number) => {
    setCurrentPage(number);
  };

  const handleChange = (e) => {
    setCoachPerPage(e.target.value);
  };

  const handleSearch = (e) => {
    setSearch(e?.target?.value);
  };

  const deBounceOnChange = debounce(handleSearch, 500);

  return (
    <main>
      <div className="coaches_title">
        <h1>Coaches</h1>
        <button
          className="coaches_button"
          onClick={() => {
            setaddPopup(true);
          }}
        >
          Add Coach +
        </button>
      </div>
      {addpopup && (
        <PopupForm action="Add" person="Coaches" setPopup={setaddPopup} />
      )}
      <div className="filter">
        {currentPage > 1 ? (
          ""
        ) : (
          <div>
            <select name="" id="" onChange={handleChange}>
              {temp?.map((val) => {
                return (
                  <option key={val} value={val}>
                    {val}
                  </option>
                );
              })}
            </select>
            <span>
              show {coachPerPage} out of {total?.length}
            </span>
          </div>
        )}
        <input
          className="w-25"
          type="text"
          onChange={deBounceOnChange}
          placeholder="Search"
        />
      </div>

      <div className="col-11">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone no.</th>
                <th scope="col">View</th>
                <th scope="col">View players</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {coaches?.length > 0 ? (
                coaches?.map((coach) => {
                  return (
                    <tr key={coach.id}>
                      <td className="fw-bold">
                        <img
                          src={coach.profile_picture}
                          alt=""
                          id="table_img"
                        />{" "}
                        {coach.full_name}{" "}
                        <span className="text-danger fw-normal">
                          {coach.is_deleted ? " ( deleted coach )" : ""}
                        </span>
                      </td>
                      <td>{coach.email}</td>
                      <td>{coach.phone}</td>
                      <td>
                        <Link to={`/view/${coach.id}`}>
                          <GrView />
                        </Link>
                        
                      </td>
                      <td>
                      <Link to={`/coachplayers/${coach.user_id}`}>
                          <FaUserFriends />
                        </Link>
                        
                      </td>
                      <td>
                        <FiEdit
                          className="icon-cursor"
                          onClick={() => {
                            seteditPopup(coach.id);
                          }}
                        />
                        {editpopup === coach.id && (
                          <PopupForm
                            coach_id={coach.id}
                            action="Edit"
                            person="Coaches"
                            setPopup={seteditPopup}
                          />
                        )}
                      </td>
                      {!coach.is_deleted ? (
                        <td>
                          <AiOutlineDelete
                            className="icon-cursor"
                            onClick={() => {
                              setdeletePopup(coach.id);
                            }}
                          />
                          {deletepopup === coach.id && (
                            <DeletePopup
                              coach_id={coach.id}
                              setPopup={setdeletePopup}
                            />
                          )}
                        </td>
                      ) : (
                        <span className="text-danger">deleted</span>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>
                    <h2>Coaches not Available</h2>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {search ? (
          ""
        ) : (
          <Paginations
            perPage={coachPerPage}
            total={total?.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        )}
      </div>
    </main>
  );
}

export default Coaches;
