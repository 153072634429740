import React, { useState } from 'react';
import { postLocation } from  "../apicalls/locations"; // Replace with your API call function

import { isAuthenticated } from "../apicalls/auth";
const user = isAuthenticated();
function LocationFormComponent({onLocationSubmit,onClose}) {
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const handleLatitudeChange = (event) => {
    setLatitude(event.target.value);
  };

  const handleLongitudeChange = (event) => {
    setLongitude(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Perform validation or checks on latitude and longitude

    // Call your API function to post the location data
    try {
      let raw = {
        "position": {
          "latitude": latitude,
          "longitude":longitude
        }
      };
      const response = await postLocation(raw, user.access_token);
      console.log('Location submitted:', response);
      onLocationSubmit(response);
      // Clear the form after successful submission
      setLatitude('');
      setLongitude('');
    } catch (error) {
      console.error('Error submitting location:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-3">
      <div className="mb-3">
        <label className="form-label">Latitude:</label>
        <input
          type="text"
          className="form-control"
          value={latitude}
          onChange={handleLatitudeChange}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Longitude:</label>
        <input
          type="text"
          className="form-control"
          value={longitude}
          onChange={handleLongitudeChange}
        />
      </div>
      <button type="submit" className="btn btn-primary">Submit</button>
      <button type="button" className="btn btn-secondary" onClick={onClose}>
        Close
      </button>
    </form>
  );
}

export default LocationFormComponent;
