import "./style/Coaches.css";

import logo from "../../assets/images.png";

import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import LocationForm from "./PopupForm/LocationForm";
import { useEffect, useState } from "react";
import { getAllLocations, getTotalLocations,postLocation } from "../apicalls/locations";
import { isAuthenticated } from "../apicalls/auth";
import DeletePopup from "./PopupForm/DeletePopup";
import Paginations from "./Paginations";
import LocationFormComponent from "./LocationFormComponent";
import debounce from "lodash.debounce";

function LocationSearch() {
  const [temp, setTemp] = useState([]);

  const [addPopup, setaddPopup] = useState(false);
  const [editPopup, seteditPopup] = useState(false);
  const [locations, setLocations] = useState([]);
  const [search, setSearch] = useState("");
  const [deletepopup, setdeletePopup] = useState(false);
  const [total, setTotal] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage, setPerPage] = useState(10);
  const [coachesData, setCoachesData] = useState([]);
  const user = isAuthenticated();

  useEffect(() => {
    getAllLocations(user.access_token, PerPage, currentPage, search).then(
      (data) => {
        setLocations(data);
      }
    );

    getTotalLocations(user.access_token).then((data) => {
      setTotal(data);
      let temp2 = [];
      for (let i = currentPage; i <= Math.ceil(data.length / 10); i++) {
        temp2.push(i * 10);
      }
      setTemp(temp2);
    });
  }, [addPopup, editPopup, PerPage, search, deletepopup, currentPage]);

  const handleChange = (e) => {
    setPerPage(e.target.value);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const deBounceOnChange = debounce(handleSearch, 500);

  // const indexOfLast = currentPage * PerPage;
  // const indexOfFirst = indexOfLast - PerPage;
  // const current =
  //   locations.length > 0
  //     ? locations?.slice(indexOfFirst, indexOfLast)
  //     : locations;

  const paginate = (number) => {
    setCurrentPage(number);
  };

  const handleOnClose = (submittedData) => {
    setaddPopup(false);
  }
  const handleLocationSubmit = (submittedData) => {
    // Handle the submitted data received from LocationFormComponent
    console.log('Submitted Location Data:', submittedData);
    setCoachesData(submittedData?.coaches);
    setLocations(submittedData?.data?.data);
    // You can perform additional actions with the submitted data here
    // For example, call an API function to post the location data to the server
    // postLocation(submittedData.latitude, submittedData.longitude);
  };

  return (
    <main>
      <div className="coaches_title">
        <h1>LocationSearch</h1>
        <button
          className="coaches_button"
          onClick={() => {
            setaddPopup(true);
          }}
        >
          Search coordinates +
        </button>
      </div>
      {addPopup && <LocationFormComponent onLocationSubmit={handleLocationSubmit} onClose={handleOnClose}/>}
      <div className="filter">
        {currentPage > 1 ? (
          ""
        ) : (
          <div>
            <select name="" id="" onChange={handleChange}>
              {temp.map((val) => {
                return (
                  <option key={val} value={val}>
                    {val}
                  </option>
                );
              })}
            </select>
            <span>
              show {PerPage} out of {total?.length}
            </span>
          </div>
        )}
        <input
          className="w-25"
          type="text"
          onChange={deBounceOnChange}
          placeholder="Search"
        />
      </div>

      <div className="col-11">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">latitude</th>
                <th scope="col">longitude</th>
                <th scope="col">Address</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {locations?.length > 0 ? (
                locations.map((location) => {
                  return (
                    <tr key={location.id}>
                      <td>{location.latitude}</td>
                      <td>{location.longitude}</td>

                      <td>{location.location}</td>
                      <td>
                        <FiEdit
                          className="icon-cursor"
                          onClick={() => {
                            seteditPopup(location.id);
                          }}
                        />
                        {editPopup === location.id && (
                          <LocationForm
                            action="Edit"
                            location_id={location.id}
                            setPopup={seteditPopup}
                          />
                        )}
                      </td>
                      <td>
                        <AiOutlineDelete
                          className="icon-cursor"
                          onClick={() => {
                            setdeletePopup(location.id);
                          }}
                        />
                        {deletepopup === location.id && (
                          <DeletePopup
                            location_id={location.id}
                            setPopup={setdeletePopup}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>
                    <h2>LocationSearch not Available</h2>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {search ? (
          ""
        ) : (
          <Paginations
            perPage={PerPage}
            total={total?.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        )}
      </div>
      {coachesData!==undefined &&   <div className="col-11">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Coach Name</th>
                <th scope="col">Email</th>
                <th scope="col">Hourly Rate</th>
                {/* Add more columns as needed */}
              </tr>
            </thead>
            <tbody>
              {coachesData.map((coach) => (
                <tr key={coach.id}>
                  <td>{coach.coach_name}</td>
                  <td>{coach.email}</td>
                  <td>{coach.hourly_rate}</td>
                  {/* Add more columns as needed */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>}
   
    </main>
  );
}

export default LocationSearch;
