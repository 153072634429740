import "./style/CoachView.css";
import logo from "../../assets/images.png";

// import logo from "../../assets/eleven.jpg";
// import logo2 from "../../assets/img2.jpg";
import { Link } from "react-router-dom";
// import {FiEdit} from "react-icons/fi";
// import {AiOutlineDelete} from "react-icons/ai";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { isAuthenticated } from "../apicalls/auth";
import {
  getCoach,
  getTransCoach,
  getTransCoachTotal,
} from "../apicalls/coaches";
import Paginations from "./Paginations";
import NumberFormat from "react-number-format";
import debounce from "lodash.debounce";

function CoachView() {
  const { id } = useParams();
  const user = isAuthenticated();
  const [temp, setTemp] = useState([]);

  const [transaction, setTransaction] = useState([]);
  const [coach, setCoach] = useState([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage, setPerPage] = useState(10);

  const handleChange = (e) => {
    setPerPage(e.target.value);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const deBounceOnChange = debounce(handleSearch, 500);

  useEffect(() => {
    getCoach(id, user.access_token).then((data) => {
      setCoach(data);
    });

    getTransCoach(id, user.access_token, PerPage, currentPage, search).then(
      (data) => {
        setTransaction(data);
        console.log(data);
      }
    );

    getTransCoachTotal(user.access_token, id).then((data) => {
      setTotal(data);
      let temp2 = [];
      for (let i = currentPage; i <= Math.ceil(data?.length / 10); i++) {
        temp2.push(i * 10);
      }
      setTemp(temp2);
    });
  }, [PerPage, search, currentPage]);

  // const indexOfLast = currentPage * PerPage;
  // const indexOfFirst = indexOfLast - PerPage;
  // const current =
  //   transaction.length > 0
  //     ? transaction?.slice(indexOfFirst, indexOfLast)
  //     : transaction;
  const paginate = (number) => {
    setCurrentPage(number);
  };

  return (
    <main>
      <Link to="/Coaches">
        <button id="back_btn">back</button>
      </Link>
      <div className="row my-3">
        <div className="col-lg-6 col-md-12">
          <div className="d-flex ">
            <img src={coach[0]?.profile_picture} alt="" className="head_img" />

            <div className="ms-4">
              <h2 className="fw-bold">{coach[0]?.full_name}</h2>

              <span>{coach[0]?.about_me}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="coaches_title">
        <h1>Transactions</h1>
      </div>
      <div className="filter">
        {currentPage > 1 ? (
          ""
        ) : (
          <div>
            <select name="" id="" onChange={handleChange}>
              {temp.map((val) => {
                return (
                  <option key={val} value={val}>
                    {val}
                  </option>
                );
              })}
            </select>
            <span>
              show {PerPage} out of {total?.length}
            </span>
          </div>
        )}
        <input
          className="w-25"
          type="text"
          onChange={deBounceOnChange}
          placeholder="Search"
        />
      </div>

      <div className="col-11">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Player Name</th>
                <th scope="col">Date</th>
                <th scope="col">Status</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody>
              {transaction.length > 0 ? (
                transaction?.map((trans) => {
                  return (
                    <tr key={trans.id}>
                      <td className="fw-bold d-flex">
                        <span>
                          <img
                            src={trans.profile_picture}
                            alt=""
                            id="table_img"
                          />
                        </span>
                        <span>
                          <span>{trans.full_name} </span>
                          <span style={{ fontSize: "13px" }}>
                            ({new Date(trans.start_date_time).toLocaleString()}{" "}
                            to {new Date(trans.end_date_time).toLocaleString()},{" "}
                            {trans.location})
                          </span>
                        </span>
                      </td>
                      <td>
                        {new Date(trans.transaction_date_time).toLocaleString()}
                      </td>
                      <td
                        className={
                          trans.payment_status === "Success"
                            ? "success fw-bold"
                            : "error fw-bold"
                        }
                      >
                        {trans.payment_status}
                        {trans.is_refund ? (
                          <span className="error" style={{ fontSize: "13px" }}>
                            (refunded)
                          </span>
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="fw-bold">
                        <NumberFormat
                          value={trans.amount / 100}
                          prefix={"$"}
                          displayType={"text"}
                          thousandSeparator={true}
                        ></NumberFormat>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>
                    <h2>Transaction not Available</h2>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {search ? (
          ""
        ) : (
          <Paginations
            perPage={PerPage}
            total={total?.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        )}
      </div>
    </main>
  );
}

export default CoachView;
