import "./style/PopupForm.css";
import { GrClose } from "react-icons/gr";
import { useEffect, useState } from "react";
import {
  addLocation,
  getLocation,
  updateLocation,
} from "../../apicalls/locations";

import { isAuthenticated } from "../../apicalls/auth";
import Autocomplete from "react-google-autocomplete";
import { extractFromAddress } from "../../utils";
import { googleApiKey } from "../../../constAPI";

function LocationForm({ action, setPopup, location_id }) {
  const [values, setValues] = useState({
    latitude: 0,
    longitude: 0,
    location: "",
    address_components: {},

    error: false,
    success: false,
    loading: false,
  });

  const user = isAuthenticated();

  const {
    latitude,
    longitude,
    location,
    error,
    success,
    loading,
    address_components,
  } = values;

  useEffect(() => {
    if (action === "Edit") {
      getLocation(location_id, user.access_token).then((data) => {
        setValues({
          ...values,
          latitude: data[0].latitude,
          longitude: data[0].longitude,
          location: data[0].location,
        });
      });
    }
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: false, success: false, loading: false });
    if (action === "Add") {
      if (!latitude || !longitude || !location) {
        setValues({
          ...values,
          error: "Please select valid location",
          success: false,
          loading: false,
        });
        return;
      }
      addLocation(
        {
          user_id: user.user_id,
          latitude,
          longitude,
          location,
          address_components,
        },
        user.access_token
      ).then((data) => {
        if (data[0]?.id) {
          setValues({
            ...values,
            latitude: 0,
            longitude: 0,
            location: "",
            error: false,
            success: true,
            loading: false,
          });
        } else {
          setValues({ ...values, error: true, success: false, loading: false });
        }
      });
    }
    if (action === "Edit") {
      if (!latitude || !longitude || !location) {
        setValues({
          ...values,
          error: "Please select valid location",
          success: false,
          loading: false,
        });
        return;
      }
      updateLocation(
        location_id,
        {
          latitude,
          longitude,
          location,
          address_components,
        },
        user.access_token
      ).then((data) => {
        if (data[0].id) {
          setValues({
            ...values,
            latitude: 0,
            longitude: 0,
            location: "",
            error: false,
            success: true,
            loading: false,
          });
        } else {
          setValues({ ...values, error: true, success: false, loading: false });
        }
      });
    }
  };

  return (
    <div className="popup">
      <div className="popup-inner">
        <div
          className="close-btn"
          onClick={() => {
            setPopup("");
          }}
        >
          <GrClose />
        </div>
        <h1>{action} Location</h1>.
        <div className="container">
          <div className="row">
            {error ? <span className="error">{error}</span> : ""}
            {success ? (
              <span className="success">
                Location {action === "Add" ? "Added" : "Edited"} Successfully
              </span>
            ) : (
              ""
            )}

            <div>
              <Autocomplete
                apiKey={googleApiKey}
                style={{
                  padding: " 6px 7px",
                  borderRadius: "5px",
                  border: "rgba(92, 90, 90, 0.616) 2px solid",
                  width: "70%",
                }}
                options={{
                  types: ["geocode", "establishment"],
                  fields: [
                    "name",
                    "photos",
                    "address_components",
                    "geometry",
                    "formatted_address",
                  ],
                }}
                onPlaceSelected={(places) => {
                  console.log(places);
                  const lat = places.geometry.location.lat();
                  const lng = places.geometry.location.lng();
                  const loc = places.formatted_address.startsWith(places.name)
                    ? places.formatted_address
                    : `${places.name} ${places.formatted_address}`;

                  const country = extractFromAddress(
                    places.address_components,
                    "country",
                    "long_name"
                  );
                  const houseNumber = extractFromAddress(
                    places.address_components,
                    "street_number",
                    "long_name"
                  );
                  const streetRoute = extractFromAddress(
                    places.address_components,
                    "route",
                    "long_name"
                  );
                  let street =
                    houseNumber && streetRoute
                      ? `${houseNumber} ${streetRoute}`
                      : null;
                  street = street ? street : streetRoute;
                  const city = extractFromAddress(
                    places.address_components,
                    "locality",
                    "long_name"
                  );
                  const state = extractFromAddress(
                    places.address_components,
                    "administrative_area_level_1",
                    "long_name"
                  );
                  const zip = extractFromAddress(
                    places.address_components,
                    "postal_code",
                    "long_name"
                  );
                  const components = {
                    houseNumber,
                    streetRoute,
                    street,
                    city,
                    state,
                    country,
                    zip,
                    formatedAddress: places.formatted_address,
                  };
                  console.log(components);
                  setValues({
                    ...values,
                    latitude: lat,
                    longitude: lng,
                    location: loc,
                    address_components: components,
                  });
                }}
              />
            </div>

            <div className=" form-content col-lg-12">
              <button onClick={onSubmit}>{action} Location</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationForm;
