import {useState} from "react";
import "./Login.css";
import {authenticate, LoginAdmin} from "../apicalls/auth";
import {Navigate} from "react-router-dom";
// import {GrClose} from "react-icons/gr";

function Login() {
  const [values, setValues] = useState({
    email: "",
    password: "",
    error: "",
    success: false,
    loading: false,
    redirect: false,
  });

  const {email, password, error, success, loading, redirect} = values;

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      error: false,
      success: false,
      [name]: event.target.value,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setValues({...values, error: "", loading: true, redirect: false});
    if (!email || !password) {
      setValues({
        ...values,
        error: "Invalid email or password",
        success: false,
        loading: false,
      });
      return;
    }
    LoginAdmin({email, password}).then((data) => {
      if (data?.user_type === 3) {
        authenticate(data, () => {
          setValues({
            ...values,
            error: false,
            success: true,
            loading: false,
            redirect: true,
            email: "",
            password: "",
          });
        });
      } else {
        setValues({
          ...values,
          error: data?.detail ? data?.detail : "Unauthrized user",
          success: false,
          loading: false,
          redirect: false,
        });
      }
    });
  };

  return (
    <div className="login_body">
      <div className="login_inner">
        <h1 className="ms-2">Login</h1>
        {redirect ? <Navigate to="/" /> : ""}
        {error ? <span className="error ms-2">{error}</span> : ""}
        <div className="container">
          <div className="row">
            <div className="form-content col-lg-12">
              <label htmlFor="">Email</label>
              <br />
              <input
                value={email}
                onChange={handleChange("email")}
                type="text"
                placeholder="Enter your Email"
              />
            </div>
            <div className="form-content col-lg-12">
              <label htmlFor="">Password</label>
              <br />
              <input
                value={password}
                type="password"
                onChange={handleChange("password")}
                placeholder="Enter your password"
              />
            </div>

            <div className="form-content col-lg-12">
              <button onClick={onSubmit}>
                {loading ? (
                  <div className="spinner-border me-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Login"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
