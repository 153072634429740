import "./style/PopupForm.css";
import { GrClose } from "react-icons/gr";
import { useEffect, useState } from "react";
import {
  addCoach,
  getCoach,
  signupCoach,
  updateCoach,
} from "../../apicalls/coaches";
import { isAuthenticated } from "../../apicalls/auth";

function PopupForm({ action, setPopup, coach_id }) {
  const [values, setValues] = useState({
    full_name: "",
    about_me: "",
    hourly_rate: 0,
    phone: 0,
    password: "",
    email: "",
    error: false,
    success: false,
    loading: false,
  });

  const user = isAuthenticated();

  const {
    full_name,
    about_me,
    hourly_rate,
    password,
    email,
    phone,
    error,
    success,
    loading,
  } = values;

  useEffect(() => {
    if (action === "Edit") {
      getCoach(coach_id, user.access_token).then((data) => {
        setValues({
          ...values,
          full_name: data[0]?.full_name,
          about_me: data[0]?.about_me,
          hourly_rate: data[0]?.hourly_rate,
          phone: Number(data[0]?.phone),
        });
      });
    }
  }, []);

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      error: false,
      success: false,
      [name]: event.target.value,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: false, success: false, loading: false });
    if (action === "Add") {
      if (!full_name || !email || !password) {
        setValues({
          ...values,
          error: "please fill required fields",
          success: false,
        });
        return;
      }
      signupCoach({ email, password, user_type: 1 }).then((data1) => {
        if (data1?.err?.code === "23505") {
          return setValues({
            ...values,
            error: "Email already Exist",
            success: false,
            loading: false,
          });
        }
        addCoach(
          {
            full_name,
            about_me,
            hourly_rate,
            phone,
            user_id: data1.user_id,
          },
          user.access_token
        ).then((data) => {
          console.log(data);
          if (data[0]?.id) {
            setValues({
              ...values,
              full_name: "",
              about_me: "",
              hourly_rate: "",
              phone: "",
              email: "",
              password: "",
              error: false,
              success: true,
              loading: false,
            });
          } else {
            setValues({
              ...values,
              error: "Please enter required fields",
              success: false,
              loading: false,
            });
          }
        });
      });
    }
    if (action === "Edit") {
      console.log("update");
      if (!full_name) {
        setValues({ ...values, error: "please fill fields", success: false });
        return;
      }
      updateCoach(coach_id, user.access_token, {
        full_name,
        about_me,
        hourly_rate,
        phone,
      }).then((data) => {
        if (data[0].id) {
          setValues({
            ...values,
            full_name: "",
            about_me: "",
            hourly_rate: "",
            phone: "",
            error: false,
            success: true,
          });
        } else {
          setValues({ ...values, error: true, success: false, loading: false });
        }
      });
    }
  };

  return (
    <div className="popup">
      <div className="popup-inner">
        <div
          className="close-btn"
          onClick={() => {
            setPopup("");
          }}
        >
          <GrClose />
        </div>
        <h1>{action} Coach</h1>.
        <div className="container">
          <div className="row">
            {error ? <span className="error">{error}</span> : ""}
            {success ? (
              <span className="success">
                Coach {action === "Add" ? "Added" : "Edited"} Successfully
              </span>
            ) : (
              ""
            )}
            <div className="form-content col-lg-6">
              <label htmlFor="">Full Name*</label>
              <br />
              <input
                type="text"
                value={full_name}
                onChange={handleChange("full_name")}
                placeholder="Enter Full Name"
                required
              />
            </div>
            {action === "Add" ? (
              <div className="form-content col-lg-6">
                <label htmlFor="">Email Address*</label>
                <br />
                <input
                  type="email"
                  value={email}
                  onChange={handleChange("email")}
                  placeholder="Enter Email Address"
                  required
                />
              </div>
            ) : (
              ""
            )}
            <div className="form-content col-lg-6">
              <label htmlFor="">About Me</label>
              <br />
              <input
                type="text"
                value={about_me}
                onChange={handleChange("about_me")}
                placeholder="Enter about coach"
              />
            </div>
            <div className="form-content col-lg-6">
              <label htmlFor="">Hourly Rate</label>
              <br />
              <input
                type="number"
                value={hourly_rate}
                onChange={handleChange("hourly_rate")}
                placeholder="Enter Hourly Rate"
              />
            </div>
            <div className="form-content col-lg-6">
              <label htmlFor="">Phone Number</label>
              <br />
              <input
                type="number"
                value={phone}
                onChange={handleChange("phone")}
                placeholder="Enter coach no."
              />
            </div>
            {action === "Add" ? (
              <div className="form-content col-lg-6">
                <label htmlFor="">Create Password*</label>
                <br />
                <input
                  type="password"
                  value={password}
                  onChange={handleChange("password")}
                  placeholder="your password"
                  required
                />
              </div>
            ) : (
              ""
            )}
            <div className="form-content col-lg-12">
              <button onClick={onSubmit}>{action} Coach</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopupForm;
