import React, { useState, useEffect, useRef ,useCallback} from 'react';
import {
  addCoach,
  getCoach,
  signupCoach,
  updateCoach,
  getCoachNotPlayers,
} from "../../apicalls/coaches";
const DropdownComponent = ({coach_id,user,playersID,onSelectItem}) => {
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const listRef = useRef(null);

  const [selectedItem, setSelectedItem] = useState(null);

  const handleSelection = (selectedItem) => {
    setSelectedItem(selectedItem); // Update the selected item state
    console.log('Selected Item:', selectedItem);
    onSelectItem(selectedItem);
  };

  // Function to fetch data based on search term and pagination
  const fetchData = async (pageNumber) => {
    setLoading(true);
    try {
      console.log({coach_id,user,playersID});
      getCoachNotPlayers(
        coach_id, // Pass your coach ID here
        user.access_token, // Pass your access token
        playersID, // Pass players ID if needed
        searchTerm, // Pass the search term
        10, // Pass items per page if needed
        pageNumber // Pass the current page number
      ).then((data) => {

        console.log("data",{data});
        if(data.data){
          setItems(prevItems => [...prevItems, ...data.data]);
        setHasMore(data.hasMore);
        }
        
      }); // Assuming API returns a flag indicating more data available
    } catch (error) {
      // Handle error
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!hasMore) return; // Do not fetch data if hasMore is false

    fetchData(page);
  }, [page, searchTerm,hasMore]); // Fetch data when page or search term changes

  // Function to handle search term change
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // Reset page number when search term changes
    setItems([]); // Clear existing items
  };

  const handleScroll = useCallback(() => {
    if (loading || !hasMore) return;

    const list = listRef.current;
    if (list.scrollTop + list.clientHeight >= list.scrollHeight - 100) {
      setPage(prevPage => prevPage + 1);
    }
  }, [loading, hasMore]);

  useEffect(() => {
    const list = listRef.current;
    list.addEventListener('scroll', handleScroll);

    return () => {
      list.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  // CSS styles
  const dropdownContainerStyle = {
    width: '300px',
    margin: '0 auto',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  };

  const inputStyle = {
    width: '100%',
    padding: '8px',
    marginBottom: '8px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
  };

  const listItemStyle = (index) => ({
    padding: '8px',
    borderBottom: '1px solid #eee',
    listStyle: 'none',
    cursor: 'pointer',
    backgroundColor: selectedItem && selectedItem.email === items[index].email ? '#f0f0f0' : 'transparent',
  });

  const listStyle = {
    maxHeight: '200px', // Set maximum height for the dropdown list
    overflowY: 'auto', // Enable vertical scrolling
    margin: 0,
    padding: 0,
  };

  return (
    <div style={dropdownContainerStyle} className="dropdown-container">
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch}
        style={inputStyle}
      />
      <ul style={listStyle} ref={listRef}>
        {items.map((item, index) => (
          <li key={index} 
          style={listItemStyle(index)}
          onClick={() => handleSelection(item)}
          >
            {item.email}-{item.full_name}
          </li>
        ))}
        {loading && <li>Loading...</li>}
      </ul>
    </div>
  );
};

export default DropdownComponent;
