import { API } from "../../constAPI";

export const getAllCoaches = (perPage, page, search, token) => {
  // limit = limit ? limit : undefined;
  // search = search ? search : undefined;
  return fetch(
    `${API}/api/admin/coaches?${new URLSearchParams({
      perPage,
      page,
      search,
    })}`,
    {
      headers: {
        Authorization: `token ${token}`,
        Accept: "application/json",
        "Content-type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const getTotalCoaches = (token) => {
  // limit = limit ? limit : undefined;
  // search = search ? search : undefined;
  return fetch(`${API}/api/admin/coach/total`, {
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const addCoach = (post, token) => {
  return fetch(`${API}/api/admin/coach`, {
    method: "POST",

    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(post),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const addPlayerToCoachRoster = (post, token,id) => {
  console.log("addPlayerToCoachRoster",{post})
  return fetch(`${API}/api/admin/coach/${id}/addtorooster`, {
    method: "POST",

    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(post),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const signupCoach = (post) => {
  return fetch(`${API}/api/auth/signup`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(post),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const getCoach = (id, token) => {
  console.log("get Coach");
  return fetch(`${API}/api/admin/coach/${id}`, {
    method: "GET",
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};
export const getAllLocations = (token, perPage, page, search) => {
  // limit = limit ? limit : undefined;
  // search = search ? search : undefined;
  return fetch(
    `${API}/api/admin/locations?${new URLSearchParams({
      perPage,
      page,
      search,
    })}`,
    {
      headers: {
        Authorization: `token ${token}`,
        Accept: "application/json",
        "Content-type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
};
export const getCoachNotPlayers = (id, token, playersID, search,perPage, page) => {
  console.log("POST CoachPlayers");
  return fetch(`${API}/api/admin/coach/${id}/notplayers?${new URLSearchParams({
    search,
    perPage,
    page,
  })}`, {
    method: "POST",
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify({playersID}),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const updateCoach = (id, token, post) => {
  return fetch(`${API}/api/admin/coach/${id}`, {
    method: "PATCH",
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(post),
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const deleteCoach = (id, token) => {
  return fetch(`${API}/api/admin/coach/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const getTransCoach = (id, token, perPage, page, search) => {
  // limit = limit ? limit : undefined;
  // search = search ? search : undefined;
  return fetch(
    `${API}/api/admin/coach/account/${id}?${new URLSearchParams({
      perPage,
      page,
      search,
    })}`,
    {
      method: "GET",
      headers: {
        Authorization: `token ${token}`,
        Accept: "application/json",
        "Content-type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .catch((err) => console.log(err));
};

export const getTransCoachTotal = (token, id) => {
  return fetch(`${API}/api/admin/coach/account/total/${id}`, {
    method: "GET",
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err));
};
